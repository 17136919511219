import classNames from "classnames";
import DiscordWhiteIcon from "./Icons/DiscordWhiteIcon";
import TwitterIcon from "./Icons/TwitterIcon";
import YouTubeIcon from "./Icons/YouTubeIcon";

const baseClass = classNames(
  "flex items-center justify-center rounded-full",
  "font-bold text-2xl text-white",
  "cursor-pointer outline-none focus:outline-none",
  "py-3 md:py-4 px-12 md:px-16"
);

export function TwitterButton({ className }) {
  const btnClass = classNames(baseClass, "", className);
  return (
    <a
      href="https://twitter.com/intent/follow?screen_name=ReactDayIn"
      className={btnClass}
      style={{ background: "#1DA1F2" }}
    >
      <TwitterIcon className="mr-4 fill-white" />
      Follow us
    </a>
  );
}

export function YouTubeButton({ className }) {
  const btnClass = classNames(baseClass, "", className);
  return (
    <a
      href="https://www.youtube.com/reactify_in?sub_confirmation=1"
      className={btnClass}
      style={{ background: "#CC0200" }}
    >
      <YouTubeIcon className="mr-4 text-3xl" />
      Subscribe
    </a>
  );
}

export function DiscordButton({ className }) {
  const btnClass = classNames(baseClass, "", className);
  return (
    <a
      href="https://discord.gg/99RuY3NgyS"
      className={btnClass}
      style={{ background: "#5865F2" }}
    >
      <DiscordWhiteIcon className="mr-4" />
      Join Discord
    </a>
  );
}
