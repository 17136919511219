import React from "react";
import Container from "./ui/Container";
import ShadowTitle from "./ui/ShadowTitle";
import TextureTitle from "./ui/TextureTitle";
import PatternById from "./ui/PatternById";
import PersonById from "./ui/PersonById";

const speakers = [
  "neha",
  "kamlesh",
  "taz",
  "aditya2",
  "sudhanshu",
  "prateekb",
  "ankeet",
  "ritesh",
  "gaurav",
  "navya",
  "sreetam",
  "kamal",
  "nivedita",
  "aditya",
  "shriram",
  "palash",
  "ananthu",
  "muskein",
  "nishan",
  "peter",
  "saurabh",
  "kiran2",
  "prateek",
  "ameer",
  "rahul2",
  "jasbir",
  "dhaiwat",
  "suprabha",
  "nikhil",
  "sai",
  "manohar",
  "zubair",
  "mohit",
  "shirsh",
  "shashwat",
  "parth",
  "shivam",
  "nikhil2",
];

export default function Speakers() {
  return (
    <Container id="speakers" className="py-2 lg:py-10">
      <div className="flex flex-col md:flex-row mb-4 lg:mb-10">
        <div className="flex-1 flex flex-col items-center lg:items-start">
          <div className="hidden lg:block ml-32">
            <PatternById id="speakerShapes" width={512} className="" />
          </div>
          <div className="hidden lg:block -mt-12">
            <TextureTitle title="Speakers" />
          </div>
          <div className="block lg:hidden">
            <ShadowTitle>Speakers</ShadowTitle>
          </div>
        </div>
      </div>
      <div className="block md:grid md:grid-cols-2 lg:grid-cols-4 gap-y-4">
        {speakers.map((speaker) => (
          <div key={speaker}>
            <PersonById id={speaker} />
          </div>
        ))}
        {/* <div className="hidden lg:grid col-span-2 row-start-1 col-start-3 justify-end items-start -mt-72">
          <PatternById id="coffee" width={512} loading="eager" />
        </div> */}
      </div>
    </Container>
  );
}

// const speakers = [
//   "sid",
//   "pavithra",
//   "prateekb",
//   "neha",
//   "sudhanshu",
//   "sanket",
//   "evan",
//   "nitish",
//   "naman",
//   "ankita",
//   "muskein",
//   "satyajit",
//   "dipti",
//   "aman",
//   "vivek",
//   "rahul",
//   "ankeet",
//   "tanisha",
//   "aditya",
//   "thiyagaraj",
//   "rajat",
//   "saurabh",
//   "megha",
//   "sooraj",
//   "patro",
//   "nikhil",
//   "sreetam",
//   "prateek",
//   // "manas",
//   "dane",
//   "anshuman",
// ];
