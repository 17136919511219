import React from "react";
import PrimaryButton from "./ui/PrimaryButton";
import Container from "./ui/Container";
import PatternById from "./ui/PatternById";
import TextureTitle from "./ui/TextureTitle";
import ShadowTitle from "./ui/ShadowTitle";
import logo from "../images/Logos/Logo_Dark/Logo.svg";
import Line from "./ui/Line";
import config from "../data/config";

function ConfDate() {
  return (
    <>
      <div className="text-2xl font-bold text-center md:text-left leading-normal">
        Jun 8-9, 2022
      </div>
      <div className="text-xl text-gray-500 text-center md:text-left">
        Remote
      </div>
    </>
  );
}

function MobileHero() {
  return (
    <Container className="py-2 md:py-10 px-8">
      <div className="absolute right-0 mt-4">
        <PatternById id="mobileHeroShapes" width={96} />
      </div>
      <div className="pt-2">
        {/* <TextureTitle title="React" />
        <TextureTitle title="Day" /> */}
        <ShadowTitle className="mb-6">React</ShadowTitle>
        <ShadowTitle>Day</ShadowTitle>
      </div>
      <PatternById id="bangalore" layout="responsive" loading="eager" />
      <ConfDate />
      {/* {config.ENABLE_REGISTRATION && (
        <div className="mt-10 flex justify-center">
          <PrimaryButton to="/register">Register</PrimaryButton>
        </div>
      )} */}
      {config.ENABLE_REGISTRATION && (
        <div className="mt-10 flex justify-center">
          <PrimaryButton to="https://hopin.com/events/react-day-bangalore-2022">
            Register
          </PrimaryButton>
        </div>
      )}
    </Container>
  );
}

function LargeHero() {
  return (
    <div className="flex flex-col items-center mt-6">
      <div className="w-full md:w-2/3 mr-2">
        <div className="flex flex-row">
          <div className="flex-1 flex flex-col items-end">
            <Line />
            <div className="flex items-start">
              <img src={logo} className="w-1/2 px-10 py-5" />
              <div className="flex w-1/2 flex-1 flex-col items-start">
                <div className="mb-3">
                  <TextureTitle title="React" />
                </div>
                <div className="w-full border-r-4">
                  <TextureTitle title="Day" />
                </div>
              </div>
            </div>
            <div className="text-right p-10 pb-0 border-r-4">
              <p className="text-2xl">
                React conference dedicated to bringing 2000+ react developers
                together.
              </p>
            </div>
          </div>
          <div className="flex-1 flex flex-col justify-end">
            <div className="px-10">
              <div className="w-2/3 pl-5">
                <PatternById
                  id="bangalore"
                  layout="responsive"
                  loading="eager"
                  priority={true}
                />
              </div>
              <div className="">
                <ConfDate />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {config.ENABLE_REGISTRATION && (
        <div className="mt-16">
          <PrimaryButton to="/register" showBackgroundCircle>
            Register
          </PrimaryButton>
        </div>
      )} */}
      {config.ENABLE_REGISTRATION && (
        <div className="mt-16">
          <PrimaryButton
            href="https://hopin.com/events/react-day-bangalore-2022"
            showBackgroundCircle
          >
            Register
          </PrimaryButton>
        </div>
      )}
    </div>
  );
}

export default function HeroBanner() {
  return (
    <>
      <div className="block md:hidden">
        <MobileHero />
      </div>
      <div className="hidden md:block">
        <LargeHero />
      </div>
    </>
  );
}
