import React from "react";
import Button from "./Button";
import classNames from "classnames";

interface IProps {
  to?: string;
  href?: string;
  children: React.ReactNode;
  className?: string;
  showBackgroundCircle?: boolean;
  disabled?: boolean;
}

const BackgroundCircle = () => (
  <div
    style={{
      position: "absolute",
      width: "120px",
      height: "120px",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      background: "white",
      borderRadius: "100%",
    }}
  />
);

export default function PrimaryButton({
  to,
  href,
  children,
  className,
  showBackgroundCircle,
  disabled,
  onClick,
}: IProps) {
  const className1 = classNames(
    className,
    "bg-blue rounded-full",
    "font-bold text-2xl text-white",
    "outline-none focus:outline-none",
    "py-3 md:py-4 px-12 md:px-16",
    {
      "bg-gray-500": disabled,
      "cursor-pointer": href || to,
      "cursor-not-allowed": disabled,
      "ns-primary-buton": !disabled,
    }
  );
  return (
    <div style={{ position: "relative" }}>
      {/* {showBackgroundCircle && <BackgroundCircle />} */}
      <Button
        to={to}
        href={href}
        className={className1}
        disabled={disabled}
        onClick={onClick}
      >
        {children}
      </Button>
    </div>
  );
}
