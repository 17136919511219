export const day1 = [
  {
    time: "10:00 AM IST",
    title: "React 18: New features and updates",
    type: "full talk",
    speaker: "neha",
  },
  {
    time: "10:30 AM IST",
    title: "One step back, two steps forward",
    type: "full talk",
    speaker: "kamlesh",
  },
  {
    time: "11:00 AM IST",
    title: "Design tokens: Scaling designs in your UI library",
    type: "lightning talk",
    speaker: "shashwat",
  },
  {
    time: "11:10 AM IST",
    type: "break",
  },
  {
    time: "11:30 AM IST",
    title: "The Perfect Dark Mode",
    type: "full talk",
    speaker: "sreetam",
  },
  {
    time: "12:00 PM IST",
    title: "What makes a good UI library",
    type: "full talk",
    speaker: "dhaiwat",
  },
  {
    time: "12:30 PM IST",
    title: "SEO Performant React Apps",
    type: "lightning talk",
    speaker: "kiran2",
  },
  {
    time: "12:40 PM IST",
    title: 'The subtle art of "Subtle" loading',
    type: "lightning talk",
    speaker: "nikhil",
  },
  {
    time: "12:50 PM IST",
    title: "Intelligent lazy loading in React.js",
    type: "lightning talk",
    speaker: "nikhil2",
  },
  {
    time: "01:00 PM IST",
    type: "break",
  },
  {
    time: "01:50 PM IST",
    title:
      "Solving coverage issue through async reducers and reducing app bundle size in process​",
    type: "lightning talk",
    speaker: "mohit",
  },
  {
    time: "02:00 PM IST",
    title: "Zust do it! Simplified state management using Zustand",
    type: "full talk",
    speaker: "sai",
  },
  {
    time: "02:30 PM IST",
    title: "Concurrent React ♥️ 3D",
    type: "full talk",
    speaker: "shriram",
  },
  {
    time: "03:00 PM IST",
    title: "Building Collaborative applications in React",
    type: "full talk",
    speaker: "ameer",
  },
  {
    time: "03:30 PM IST",
    type: "break",
  },
  {
    time: "03:50 PM IST",
    title: "Finding memory leaks with Chrome Dev tools​",
    type: "lightning talk",
    speaker: "zubair",
  },
  {
    time: "04:00 PM IST",
    title:
      "Accelerating UI Engg and Improving Customer Experience using Developer Toolkit​",
    type: "full talk",
    speaker: "navya",
  },
  {
    time: "04:30 PM IST",
    title: "Cypress - The React Application End To End Testing Tool",
    type: "full talk",
    speaker: "rahul2",
  },
  {
    time: "05:00 PM IST",
    title: "Frontend Architecture",
    type: "panel discussion",
    speakers: ["sudhanshu", "prateekb", "ankeet", "ritesh"],
  },
];

export const day2 = [
  {
    time: "10:00 AM IST",
    title: "Improving performance for React & Electron Apps",
    type: "full talk",
    speaker: "kamal",
  },
  {
    time: "10:30 AM IST",
    title: "Cracking a web based metaverse experience with WebRTC",
    type: "full talk",
    speaker: "shivam",
  },
  {
    time: "11:00 AM IST",
    title: "Creating Experience in MetaVerse",
    type: "lightning talk",
    speaker: "suprabha",
  },
  {
    time: "11:10 AM IST",
    type: "break",
  },
  {
    time: "11:30 AM IST",
    title: "The Frontend Framework of our Dreams?",
    type: "full talk",
    speaker: "saurabh",
  },
  {
    time: "12:00 PM IST",
    title: "If you know React, you'll love Solid",
    type: "lightning talk",
    speaker: "aditya",
  },
  {
    time: "12:10 PM IST",
    title: "React conditional props with Typescript",
    type: "lightning talk",
    speaker: "gaurav",
  },
  {
    time: "12:20 PM IST",
    title: "How Rescript changed the way we think?",
    type: "lightning talk",
    speaker: "prateek",
  },
  {
    time: "12:30 PM IST",
    title: "How we integrated React Native in existing native Apps",
    type: "full talk",
    speaker: "jasbir",
  },
  {
    time: "01:00 PM IST",
    type: "break",
  },
  {
    time: "1:50 PM IST",
    title: "Handling Dynamic links 101",
    type: "lightning talk",
    speaker: "manohar",
  },
  {
    time: "02:00 PM IST",
    title: "Trying to understand new architecture of react-native with JSI",
    type: "full talk",
    speaker: "aditya2",
  },
  {
    time: "02:30 PM IST",
    title: "Offline workflow in React Native with WatermelonDB",
    type: "full talk",
    speaker: "shirsh",
  },
  {
    time: "03:00 PM IST",
    title: "Developing Multi-platform WebRTC SDKs in React Native",
    type: "full talk",
    speaker: "palash",
  },
  {
    time: "03:30 PM IST",
    type: "break",
  },
  {
    time: "03:50 PM IST",
    title: "Native-like Experience with Reactive WebView",
    type: "lightning talk",
    speaker: "parth",
  },
  {
    time: "04:00 PM IST",
    title: "React Native Everywhere",
    type: "full talk",
    speaker: "taz",
  },
  {
    time: "04:30 PM IST",
    title: "React Native A11y Basics",
    type: "full talk",
    speaker: "nivedita",
  },
  {
    time: "05:00 PM IST",
    title: "React Native",
    type: "panel discussion",
    speakers: ["ananthu", "nishan", "muskein", "peter"],
  },
];
