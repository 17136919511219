const people = [
  {
    id: "megha",
    firstName: "Megha",
    lastName: "Pathak",
    title: "Software Developer",
    company: "Ex-Hashnode",
    twitter: "https://twitter.com/Megha_Pathak_",
    github: "https://github.com/megha-pathak",
    linkedin: "https://www.linkedin.com/in/megha--pathak/",
    avatar: {
      src: require("../images/people/megha.png"),
    },
  },
  {
    id: "tanisha",
    firstName: "Tanisha",
    lastName: "Sabherwal",
    title: "Software Engineer",
    company: "Gameskraft",
    twitter: "https://twitter.com/tanishaaa03",
    github: "https://github.com/tanisha03",
    linkedin: "https://www.linkedin.com/in/tanishasabherwal",
    avatar: {
      src: require("../images/people/tanisha.png"),
    },
  },
  {
    id: "sreetam",
    firstName: "Sreetam",
    lastName: "Das",
    title: "Senior Frontend Engineer I",
    company: "Remote",
    twitter: "https://twitter.com/_SreetamDas",
    linkedin: "https://www.linkedin.com/in/sreetamdas",
    github: "https://github.com/sreetamdas",
    avatar: {
      src: require("../images/people/sreetam.png"),
    },
  },
  {
    id: "navya",
    firstName: "Navya",
    lastName: "Agarwal",
    title: "SDE - III",
    company: "Adobe Systems",
    twitter: "https://twitter.com/navya_agwl",
    linkedin: "https://www.linkedin.com/in/navya-agarwal/",
    github: "https://github.com/navyaagarwal23/",
    avatar: {
      src: require("../images/people/navya.png"),
    },
  },
  {
    id: "sai",
    firstName: "Sai Eashwar",
    lastName: "K S",
    title: "SDE 1",
    company: "Groww",
    twitter: "https://twitter.com/SaiEashwarKS?t=U313BvbnB4NiFa2xs5nPXA",
    linkedin: "https://www.linkedin.com/in/sai-eashwar-k-s",
    github: "https://github.com/SaiEashwarKS",
    avatar: {
      src: require("../images/people/sai.png"),
    },
  },
  {
    id: "aditya2",
    firstName: "Aditya",
    lastName: "Pahilwani",
    title: "SDE 1",
    company: "Groww",
    twitter: "https://twitter.com/AdityaPahilwani",
    linkedin: "https://www.linkedin.com/in/aditya-pahilwani-93b42bb5/",
    github: "https://github.com/AdityaPahilwani/",
    avatar: {
      src: require("../images/people/aditya2.png"),
    },
  },
  {
    id: "saurabh",
    firstName: "Saurabh",
    lastName: "Daware",
    title: "Senior Frontend Engineer",
    company: "Razorpay",
    twitter: "https://twitter.com/saurabhdawaree",
    github: "https://github.com/saurabhdaware",
    avatar: {
      src: require("../images/people/saurabh.png"),
    },
  },
  {
    id: "gaurav",
    firstName: "Gaurav",
    lastName: "Gupta",
    title: "Lead engineer",
    company: "smallcase",
    twitter: "https://twitter.com/gaurav5430",
    linkedin: "https://www.linkedin.com/in/gaurav5430",
    github: "https://github.com//gaurav5430",
    avatar: {
      src: require("../images/people/gaurav.png"),
    },
  },
  {
    id: "manohar",
    firstName: "Manohar",
    lastName: "Gunduboina",
    title: "SDE II",
    company: "LatitudePay Asia",
    twitter: "https://twitter.com/manoharglm",
    linkedin: "https://www.linkedin.com/in/manoharglm/",
    github: "https://github.com/manoharglm",
    avatar: {
      src: require("../images/people/manohar.png"),
    },
  },
  {
    id: "palash",
    firstName: "Palash",
    lastName: "Golecha",
    title: "Co-founder",
    company: "dyte",
    twitter: "https://twitter.com/palashgolecha",
    linkedin: "",
    github: "",
    avatar: {
      src: require("../images/people/palash.png"),
    },
  },
  {
    id: "zubair",
    firstName: "Zubair",
    lastName: "Ahmed",
    title: "SDE II",
    company: "Mattermost",
    twitter: "https://twitter.com/Md_ZubairAhmed",
    linkedin: "https://www.linkedin.com/in/md-zubairahmed/",
    github: "https://github.com/M-ZubairAhmed/",
    avatar: {
      src: require("../images/people/zubair.png"),
    },
  },
  {
    id: "nivedita",
    firstName: "Nivedita",
    lastName: "Singh",
    title: "Mobile Engineer Intern",
    company: "smallcase",
    twitter: "https://twitter.com/explore30666817",
    linkedin: "https://www.linkedin.com/in/nivedita-singh-0627",
    github: "https://github.com/CatWithNineLives",
    avatar: {
      src: require("../images/people/nivedita.png"),
    },
  },
  {
    id: "nikhil2",
    firstName: "Nikhil",
    lastName: "Kumaran S",
    title: "Web developer",
    company: "Cloudera",
    twitter: "https://twitter.com/iNikhilKumaran",
    linkedin: "https://www.linkedin.com/in/nikhilkumaran",
    github: "https://github.com/Nikhil-Kumaran",
    avatar: {
      src: require("../images/people/nikhil2.png"),
    },
  },
  {
    id: "aditya",
    firstName: "Aditya",
    lastName: "Agarwal",
    title: "UI-2",
    company: "Razorpay",
    twitter: "https://twitter.com/dev__adi",
    linkedin: "https://www.linkedin.com/in/itsaditya/",
    github: "https://github.com/itaditya",
    avatar: {
      src: require("../images/people/aditya.png"),
    },
  },
  {
    id: "shriram",
    firstName: "Shriram",
    lastName: "Balaji",
    title: "Software Engineer II",
    company: "Microsoft",
    twitter: "https://twitter.com/shrirambalaji",
    linkedin: "https://www.linkedin.com/in/shrirambalaji",
    github: "https://github.com/shrirambalaji",
    avatar: {
      src: require("../images/people/shriram.png"),
    },
  },
  {
    id: "kamal",
    firstName: "Kamalakannan",
    lastName: "",
    // lastName: "Nandagopal",
    title: "Staff Software Engineer",
    company: "Postman",
    twitter: "https://twitter.com/kamalaknn",
    linkedin: "https://www.linkedin.com/in/kamalakannan-nandagopal-3650818a/",
    github: "https://github.com/kamalaknn",
    avatar: {
      src: require("../images/people/kamal.png"),
    },
  },
  {
    id: "dhaiwat",
    firstName: "Dhaiwat",
    lastName: "Pandya",
    title: "Developer Advocate",
    company: "Ankr",
    twitter: "https://twitter.com/dhaiwat10",
    linkedin: "https://linkedin.com/in/dhaiwat-pandya",
    github: "https://github.com/dhaiwat10",
    avatar: {
      src: require("../images/people/dhaiwat.png"),
    },
  },
  {
    id: "rahul2",
    firstName: "Rahul",
    lastName: "Maheshwari",
    title: "SDE - IV",
    company: "Meesho",
    twitter: "https://twitter.com/ramaheshwari3",
    linkedin: "https://www.linkedin.com/in/rahulm2492/",
    github: "",
    avatar: {
      src: require("../images/people/rahul2.png"),
    },
  },
  {
    id: "mohit",
    firstName: "Mohit",
    lastName: "Maheshwari",
    title: "SDE - 1",
    company: "TATA 1mg",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/mohit-maheshwari-42851a123/",
    github: "",
    avatar: {
      src: require("../images/people/mohit.png"),
    },
  },
  {
    id: "shashwat",
    firstName: "Shashwat",
    lastName: "Sinha",
    title: "Software Engineer 2",
    company: "Vimeo",
    twitter: "",
    linkedin: "https://linkedin.com/in/shashwat-sinha-03a723147",
    github: "https://github.com/shashwat2702",
    avatar: {
      src: require("../images/people/shashwat.png"),
    },
  },
  {
    id: "shivam",
    firstName: "Shivam",
    lastName: "Verma",
    title: "Software Engineer",
    company: "Invact",
    twitter: "https://twitter.com/shivamazed",
    linkedin: "https://www.linkedin.com/in/theshivamverma/",
    github: "https://github.com/theshivamverma",
    avatar: {
      src: require("../images/people/shivam.png"),
    },
  },
  {
    id: "kamlesh",
    firstName: "Kamlesh",
    lastName: "Chandnani",
    title: "Principal Frontend Engineer",
    company: "Razorpay",
    twitter: "https://twitter.com/_kamlesh_",
    github: "https://github.com/kamleshchandnani",
    linkedin: "https://www.linkedin.com/in/kamleshchandnani/",
    avatar: {
      src: require("../images/people/kamlesh.png"),
    },
  },
  {
    id: "ameer",
    firstName: "Ameer",
    lastName: "Jhan",
    title: "Tech Lead",
    company: "Hackerrank",
    twitter: "https://twitter.com/ameerthehacker",
    linkedin: "https://www.linkedin.com/in/ameerthehacker/",
    github: "https://github.com/ameerthehacker",
    avatar: {
      src: require("../images/people/ameer.png"),
    },
  },
  {
    id: "nikhil",
    firstName: "Nikhil",
    lastName: "Sharma",
    title: "Software Engineer",
    company: "Postman",
    twitter: "https://twitter.com/niksharma1997",
    github: "https://github.com/nik72619c",
    linkedin: "https://www.linkedin.com/in/nikhil-sharma-88b04515b",
    avatar: {
      src: require("../images/people/nikhil.png"),
    },
  },
  {
    id: "parth",
    firstName: "Parth",
    lastName: "Mahajan",
    title: "Tech Lead Frontend",
    company: "TATA 1mg",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/parth-mahajan/",
    github: "",
    avatar: {
      src: require("../images/people/parth.png"),
    },
  },
  {
    id: "suprabha",
    firstName: "Suprabha",
    lastName: "",
    title: "Product Engineer",
    company: "Gojek",
    twitter: "https://twitter.com/suprabhasupi",
    linkedin: "https://www.linkedin.com/in/suprabha-s/",
    github: "https://github.com/suprabhasupi",
    avatar: {
      src: require("../images/people/suprabha.png"),
    },
  },
  {
    id: "shirsh",
    firstName: "Shirsh",
    lastName: "Zibbu",
    title: "Senior Software Developer",
    company: "Anarock",
    twitter: "",
    linkedin: "https://www.linkedin.com/in/shirsh-zibbu/",
    github: "https://github.com/zhirzh",
    avatar: {
      src: require("../images/people/shirsh.png"),
    },
  },
  {
    id: "neha",
    firstName: "Neha",
    lastName: "Sharma",
    title: "Engineering Manager",
    company: "",
    twitter: "https://twitter.com/hellonehha",
    github: "https://github.com/neha",
    linkedin: "https://www.linkedin.com/in/nehha",
    avatar: {
      src: require("../images/people/neha.png"),
    },
  },
  {
    id: "kiran2",
    firstName: "Kiran",
    lastName: "Rawat",
    title: "Software Engineer 3",
    company: "Walmart",
    twitter: "https://twitter.com/_kiran_rawat_",
    linkedin: "https://www.linkedin.com/in/kiran-rawat-696910151",
    github: "",
    avatar: {
      src: require("../images/people/kiran2.png"),
    },
  },
  {
    id: "taz",
    firstName: "Taz",
    lastName: "Singh",
    title: "Founder",
    company: "Guild",
    twitter: "https://twitter.com/tazsingh",
    linkedin: "",
    github: "https://github.com/tazsingh",
    avatar: {
      src: require("../images/people/taz.png"),
    },
  },
  {
    id: "prateek",
    firstName: "Prateek",
    lastName: "Pandey",
    title: "Tech Lead",
    company: "Porter",
    twitter: "https://twitter.com/prateek_p27",
    github: "https://github.com/Prateek13727",
    linkedin: "https://www.linkedin.com/in/prateek13727",
    avatar: {
      src: require("../images/people/prateek.png"),
    },
  },
  {
    id: "jasbir",
    firstName: "Jasbir Singh",
    lastName: "Shergill",
    title: "SDE 3",
    company: "Swiggy",
    twitter: "",
    github: "https://github.com/jasbir23",
    linkedin: "https://in.linkedin.com/in/jasbirshergill23",
    avatar: {
      src: require("../images/people/jasbir.png"),
    },
  },
  {
    id: "peter",
    firstName: "Peter",
    lastName: "Lazar",
    title: "Founding Engineer",
    company: "savage.club",
    twitter: "https://twitter.com/peterlazar1993",
    linkedin: "https://www.linkedin.com/in/peterlazar1993/",
    github: "https://github.com/peterlazar1993",
    avatar: {
      src: require("../images/people/peter.png"),
    },
  },
  {
    id: "nishan",
    firstName: "Nishan",
    lastName: "Bende",
    title: "Software Engineer",
    company: "Showtime",
    twitter: "https://twitter.com/nishanbende",
    linkedin: "https://www.linkedin.com/in/nishanbende/",
    github: "https://github.com/intergalacticspacehighway",
    avatar: {
      src: require("../images/people/nishan.png"),
    },
  },
  {
    id: "ananthu",
    firstName: "Ananthu",
    lastName: "Kanive",
    title: "Software Engineer",
    company: "smallcase",
    twitter: "https://twitter.com/ananthu_tweetin",
    linkedin: "https://www.linkedin.com/in/ananthu-kanive-18376b61/",
    github: "",
    avatar: {
      src: require("../images/people/ananthu.png"),
    },
  },
  {
    id: "muskein",
    firstName: "Muskein",
    lastName: "Singh",
    title: "Senior Software Engineer",
    company: "Microsoft",
    twitter: "https://twitter.com/Muskein",
    github: "https://github.com/muskeinsingh",
    linkedin: "https://www.linkedin.com/in/muskein-singh-68808070",
    avatar: {
      src: require("../images/people/muskein.png"),
    },
  },
  {
    id: "ritesh",
    firstName: "Ritesh",
    lastName: "Kumar",
    title: "Senior Software Developer",
    company: "PSPDFKit",
    twitter: "https://twitter.com/ritz078",
    github: "https://github.com/ritz078",
    linkedin: "https://www.linkedin.com/in/ritz078/",
    avatar: {
      src: require("../images/people/ritesh.png"),
    },
  },
  {
    id: "prateekb",
    firstName: "Prateek",
    lastName: "Bhatnagar",
    title: "Staff Software Engineer",
    company: "Coinbase",
    twitter: "https://twitter.com/_prateekbh",
    github: "https://github.com/prateekbh",
    linkedin: "https://www.linkedin.com/in/prateek-bh/",
    avatar: {
      src: require("../images/people/prateekb.png"),
    },
  },
  {
    id: "sudhanshu",
    firstName: "Sudhanshu",
    lastName: "Yadav",
    title: "Front End Architect",
    company: "Prophecy",
    twitter: "https://twitter.com/_syadav",
    github: "https://github.com/s-yadav",
    linkedin: "https://www.linkedin.com/in/yadavsudhanshu",
    avatar: {
      src: require("../images/people/sudhanshu.png"),
    },
  },
  {
    id: "ankeet",
    firstName: "Ankeet",
    lastName: "Maini",
    title: "Engineering Manager",
    company: "Disney+ Hotstar",
    twitter: "https://twitter.com/ankeetmaini",
    github: "https://github.com/ankeetmaini",
    linkedin: "https://www.linkedin.com/in/ankeetmaini/",
    avatar: {
      src: require("../images/people/ankeet.png"),
    },
  },
];

const template = {
  id: "",
  firstName: "",
  lastName: "",
  title: "",
  company: "",
  twitter: "",
  linkedin: "",
  github: "",
  avatar: {
    src: require("../images/people/sreetam.png"),
  },
};

export default people;

// const people = [
//   {
//     id: "kiran",
//     firstName: "Kiran",
//     lastName: "Abburi",
//     title: "Founder, Neostack",
//     twitter: "https://twitter.com/kiran_abburi",
//     github: "https://github.com/akiran",
//     linkedin: "https://www.linkedin.com/in/kiranabburi/",
//     avatar: {
//       src: require("../images/people/kiran.png"),
//     },
//   },
//   {
//     id: "kamlesh",
//     firstName: "Kamlesh",
//     lastName: "Chandnani",
//     title: "Principal Engineer, Razorpay",
//     twitter: "https://twitter.com/_kamlesh_",
//     github: "https://github.com/kamleshchandnani",
//     linkedin: "https://www.linkedin.com/in/kamleshchandnani/",
//     avatar: {
//       src: require("../images/people/kamlesh.png"),
//     },
//   },
//   {
//     id: "varsha",
//     firstName: "Varsha",
//     lastName: "Saha",
//     title: "Frontend engineer, Flipkart",
//     twitter: "https://twitter.com/saha_varsha",
//     github: "https://github.com/varshasaha",
//     linkedin: "https://www.linkedin.com/in/varsha-saha-0a10a0113/",
//     avatar: {
//       src: require("../images/people/varsha.png"),
//     },
//   },
//   {
//     id: "vishwa",
//     firstName: "Vishwa",
//     lastName: "Mehta",
//     title: "DevRel, Hasura",
//     twitter: "https://twitter.com/VishwaMehta30",
//     github: "https://github.com/mehtavishwa30",
//     linkedin: "https://www.linkedin.com/in/vishwa-mehta-483409160",
//     avatar: {
//       src: require("../images/people/vishwa.png"),
//     },
//   },
//   {
//     id: "sudhanshu",
//     firstName: "Sudhanshu",
//     lastName: "Yadav",
//     title: "Front-end Architect, HackerRank",
//     twitter: "https://twitter.com/_syadav",
//     github: "https://github.com/s-yadav",
//     linkedin: "https://www.linkedin.com/in/yadavsudhanshu",
//     avatar: {
//       src: require("../images/people/sudhanshu.png"),
//     },
//   },
//   {
//     id: "nitish",
//     firstName: "Nitish",
//     lastName: "Phanse",
//     title: "Senior Software Engineer, Myntra",
//     twitter: "https://twitter.com/nitish24p",
//     github: "https://github.com/nitish24p",
//     linkedin: "https://www.linkedin.com/in/nitish-phanse-b99604101",
//     avatar: {
//       src: require("../images/people/nitish.png"),
//     },
//   },
//   {
//     id: "nikhil",
//     firstName: "Nikhil",
//     lastName: "Sharma",
//     title: "Software Engineer, Postman",
//     twitter: "https://twitter.com/niksharma1997",
//     github: "https://github.com/nik72619c",
//     linkedin: "https://www.linkedin.com/in/nikhil-sharma-88b04515b",
//     avatar: {
//       src: require("../images/people/nikhil.png"),
//     },
//   },
//   {
//     id: "neha",
//     firstName: "Neha",
//     lastName: "Sharma",
//     title: "Software Development Manager, Tesco",
//     twitter: "https://twitter.com/hellonehha",
//     github: "https://github.com/neha",
//     linkedin: "https://www.linkedin.com/in/nehha",
//     avatar: {
//       src: require("../images/people/neha.png"),
//     },
//   },
//   {
//     id: "naman",
//     firstName: "Naman",
//     lastName: "Sancheti",
//     title: "Frontend Engineer II, Amazon",
//     twitter: "https://twitter.com/namans_26",
//     github: "https://github.com/namansancheti",
//     linkedin: "https://www.linkedin.com/in/namansancheti",
//     avatar: {
//       src: require("../images/people/naman.png"),
//     },
//   },
//   {
//     id: "aditya",
//     firstName: "Aditya",
//     lastName: "Agarwal",
//     title: "Web Dev, HackerRank",
//     twitter: "https://twitter.com/dev__adi",
//     github: "https://github.com/itaditya",
//     avatar: {
//       src: require("../images/people/aditya.png"),
//     },
//   },
//   {
//     id: "sreetam",
//     firstName: "Sreetam",
//     lastName: "Das",
//     title: "Frontend Engineer, Remote",
//     twitter: "https://twitter.com/_SreetamDas",
//     github: "https://github.com/sreetamdas",
//     linkedin: "https://www.linkedin.com/in/sreetamdas",
//     avatar: {
//       src: require("../images/people/sreetam.png"),
//     },
//   },
//   {
//     id: "thiyagaraj",
//     firstName: "Thiyagaraj T",
//     lastName: "",
//     title: "Frontend Engineer, Dgraph Labs",
//     twitter: "https://twitter.com/TheWizardJS",
//     github: "https://github.com/tstreamDOTh",
//     linkedin: "https://www.linkedin.com/in/thiyagarajt/",
//     avatar: {
//       src: require("../images/people/thiyagaraj.png"),
//     },
//   },
//   {
//     id: "anshuman",
//     firstName: "Anshuman",
//     lastName: "Verma",
//     title: "Frontend Engineer, Clarisights",
//     twitter: "https://twitter.com/anshumaniac",
//     github: "https://github.com/anshumanv",
//     linkedin: "https://www.linkedin.com/in/anshumanv12",
//     avatar: {
//       src: require("../images/people/anshuman.png"),
//     },
//   },
//   {
//     id: "dane",
//     firstName: "Dane",
//     lastName: "David",
//     title: "JavaScript developer, BigBinary",
//     twitter: "https://twitter.com/this_dane",
//     github: "https://github.com/danedavid",
//     linkedin: "https://www.linkedin.com/in/dane-david-75382235",
//     avatar: {
//       src: require("../images/people/dane.png"),
//     },
//   },
//   {
//     id: "muskein",
//     firstName: "Muskein",
//     lastName: "Singh",
//     title: "SDE 3, Flipkart",
//     twitter: "https://twitter.com/Muskein",
//     github: "https://github.com/muskeinsingh",
//     linkedin: "https://www.linkedin.com/in/muskein-singh-68808070",
//     avatar: {
//       src: require("../images/people/muskein.png"),
//     },
//   },
//   {
//     id: "rajat",
//     firstName: "Rajat",
//     lastName: "Gupta",
//     title: "SDE-II, Groww",
//     github: "https://github.com/Rajat421",
//     linkedin: "https://www.linkedin.com/in/rajat-gupta-b4bba08a",
//     avatar: {
//       src: require("../images/people/rajat.png"),
//     },
//   },
//   {
//     id: "vivek",
//     firstName: "Vivek Kumar",
//     lastName: "Bansal",
//     title: "Staff Software Engineer (UI), Harness",
//     twitter: "https://twitter.com/_vkbansal",
//     linkedin: "https://www.linkedin.com/in/vkbansal03",
//     github: "https://github.com/vkbansal",
//     avatar: {
//       src: require("../images/people/vivek.png"),
//     },
//   },
//   {
//     id: "prateek",
//     firstName: "Prateek",
//     lastName: "",
//     title: "Senior Software Engineer, Porter",
//     twitter: "https://twitter.com/Assault13727",
//     github: "https://github.com/Prateek13727",
//     linkedin: "https://www.linkedin.com/in/prateek13727",
//     avatar: {
//       src: require("../images/people/prateek.png"),
//     },
//   },
//   {
//     id: "manas",
//     firstName: "Manas",
//     lastName: "Jayanth",
//     title: "Software Developer",
//     twitter: "https://twitter.com/ManasJayanth",
//     github: "https://github.com/prometheansacrifice",
//     linkedin: "https://www.linkedin.com/in/manas-jayanth-40406876",
//     avatar: {
//       src: require("../images/people/manas.png"),
//     },
//   },
//   {
//     id: "patro",
//     firstName: "NC Patro",
//     lastName: "",
//     title: "Lead Engineer, Lowe's India",
//     twitter: "https://twitter.com/ncpatro",
//     github: "https://github.com/ncpatro",
//     linkedin: "https://www.linkedin.com/in/nc-patro-b4612215",
//     avatar: {
//       src: require("../images/people/patro.png"),
//     },
//   },
//   {
//     id: "sooraj",
//     firstName: "Sooraj",
//     lastName: "Sanker",
//     title: "Engineer, Hasura",
//     twitter: "https://twitter.com/soorajsanker",
//     github: "https://github.com/soorajshankar",
//     linkedin: "https://www.linkedin.com/in/soorajsanker",
//     avatar: {
//       src: require("../images/people/sooraj.png"),
//     },
//   },
//   {
//     id: "sanket",
//     firstName: "Sanket",
//     lastName: "Sahu",
//     title: "CEO, GeekyAnts",
//     twitter: "https://twitter.com/sanketsahu",
//     github: "https://github.com/sanketsahu",
//     linkedin: "https://www.linkedin.com/in/sanketsahu",
//     avatar: {
//       src: require("../images/people/sanket.png"),
//     },
//   },
//   {
//     id: "ankita",
//     firstName: "Ankita",
//     lastName: "Masand",
//     title: "Principal Frontend Engineer, Treebo Hotels",
//     twitter: "https://twitter.com/AnkitaMasand",
//     github: "https://github.com/ankitamasand",
//     linkedin: "https://www.linkedin.com/in/ankmasand",
//     avatar: {
//       src: require("../images/people/ankita.png"),
//     },
//   },
//   {
//     id: "tanisha",
//     firstName: "Tanisha",
//     lastName: "Sabherwal",
//     title: "Software Engineer",
//     company: "JP Morgan Chase & Co",
//     twitter: "https://twitter.com/tanishaaa03",
//     github: "https://github.com/tanisha03",
//     linkedin: "https://www.linkedin.com/in/tanishasabherwal",
//     avatar: {
//       src: require("../images/people/tanisha.png"),
//     },
//   },
//   {
//     id: "pavithra",
//     firstName: "Pavithra",
//     lastName: "Kodmad",
//     title: "UI Engineer, Atlassian",
//     twitter: "https://twitter.com/PKodmad",
//     github: "https://github.com/pksjce",
//     linkedin: "https://www.linkedin.com/in/pavithra-kodmad",
//     avatar: {
//       src: require("../images/people/pavithra.png"),
//     },
//   },
//   {
//     id: "sid",
//     firstName: "Siddharth",
//     lastName: "Kshetrapal",
//     title: "UI Developer, Codesandbox",
//     twitter: "https://twitter.com/siddharthkp",
//     github: "https://github.com/siddharthkp",
//     linkedin: "https://www.linkedin.com/in/siddharthkp",
//     avatar: {
//       src: require("../images/people/sid.png"),
//     },
//   },
//   {
//     id: "dipti",
//     firstName: "Dipti",
//     lastName: "Roy",
//     title: "Full Stack Developer, PayPal",
//     twitter: "https://twitter.com/diptiroy23",
//     linkedin: "https://www.linkedin.com/in/roydipti23/",
//     avatar: {
//       src: require("../images/people/dipti.png"),
//     },
//   },
//   {
//     id: "rahul",
//     firstName: "Rahul",
//     lastName: "Dhawani",
//     title: "Senior Software Engineer, Swiggy",
//     twitter: "https://twitter.com/dhawanirahul",
//     github: "https://github.com/rahuldhawani",
//     linkedin: "https://www.linkedin.com/in/rahuldhawani",
//     avatar: {
//       src: require("../images/people/rahul.png"),
//     },
//   },
//   {
//     id: "prateekb",
//     firstName: "Prateek",
//     lastName: "Bhatnagar",
//     title: "Engineer, Uber",
//     twitter: "https://twitter.com/_prateekbh",
//     github: "https://github.com/prateekbh",
//     linkedin: "https://www.linkedin.com/in/prateek-bh/",
//     avatar: {
//       src: require("../images/people/prateekb.png"),
//     },
//   },
//   {
//     id: "ankeet",
//     firstName: "Ankeet",
//     lastName: "Maini",
//     title: "Frontend Engineer, Flipkart",
//     twitter: "https://twitter.com/ankeetmaini",
//     github: "https://github.com/ankeetmaini",
//     linkedin: "https://www.linkedin.com/in/ankeetmaini/",
//     avatar: {
//       src: require("../images/people/ankeet.png"),
//     },
//   },
//   {
//     id: "aman",
//     firstName: "Aman",
//     lastName: "Mittal",
//     title: "Software Developer",
//     twitter: "https://twitter.com/amanhimself",
//     github: "https://github.com/amandeepmittal",
//     avatar: {
//       src: require("../images/people/aman.png"),
//     },
//   },
//   {
//     id: "saurabh",
//     firstName: "Saurabh",
//     lastName: "Daware",
//     title: "Frontend Developer, Razorpay",
//     twitter: "https://twitter.com/saurabhcodes",
//     github: "https://github.com/saurabhdaware",
//     avatar: {
//       src: require("../images/people/saurabh.png"),
//     },
//   },
//   {
//     id: "megha",
//     firstName: "Megha",
//     lastName: "Pathak",
//     title: "Software Developer",
//     company: "Hashnode",
//     twitter: "https://twitter.com/Megha_Pathak_",
//     github: "https://github.com/megha-pathak",
//     linkedin: "https://www.linkedin.com/in/megha--pathak/",
//     avatar: {
//       src: require("../images/people/megha.png"),
//     },
//   },
//   {
//     id: "evan",
//     firstName: "Evan",
//     lastName: "Bacon",
//     title: "Software Developer, Expo",
//     twitter: "https://twitter.com/Baconbrix",
//     github: "https://github.com/EvanBacon",
//     linkedin: "https://www.linkedin.com/in/evanbacon/",
//     avatar: {
//       src: require("../images/people/evan.png"),
//     },
//   },
//   {
//     id: "satyajit",
//     firstName: "Satyajit",
//     lastName: "Sahoo",
//     title: "Software Developer, Callstack",
//     twitter: "https://twitter.com/satya164",
//     github: "https://github.com/satya164",
//     linkedin: "https://www.linkedin.com/in/satyajit-sahoo-42945484",
//     avatar: {
//       src: require("../images/people/satyajit.png"),
//     },
//   },
// ];
